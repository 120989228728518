import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#F08300';
const footerRelatedLinks = [
	{ title: 'MJF Swipe app', url: '/fr/projets/montreux-jazz-festival-swipe-app/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				image_1: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_1.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_2.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_3.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/terre-des-hommes-myorange-campaign/',
					lang: 'en',
				},
			]}
			title="Terre des Hommes, My orange - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Terre des Hommes, </br>My orange"
				video={{
					id: '7de1ec495366ef6ea985e3db441772d6',
					alt: ''
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Des oranges dans le digital, portées par une campagne de gamification
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Research',
								'Concept',
								'UX/UI',
								'Illustration',
								'Motion Design'
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							En 2020, les restrictions sanitaires ont mis à mal la fameuse
							vente des oranges dans la rue organisée par Terre des hommes.
							Transformant la contrainte en une opportunité, l'ONG est venue
							nous trouver pour travailler sur un concept de digitalisation
							de la campagne. Objectif: rester visible pour maintenir les
							dons tout en rajeunissant la cible.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Une campagne digitale de gamification fait entrer le visiteur
							dans un univers ludique, l'encourageant à récolter le maximum
							d'oranges en un minimum de temps. Plus la récolte d'oranges
							est abondante, plus l'impact est important sur le terrain.
						</p>

						<div className="project-links text-content">
							<ButtonExternal
								href="https://myorange.tdh.ch/"
								text="Découvrir le site"
							/>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="f785b926769ea02016b6084d9364bd18"
					alt="Démo du jeu"
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_1.childImageSharp}
						alt="Plateforme média version mobile"
					/>
					<Image
						{...data.image_2.childImageSharp}
						alt="Plateforme média version mobile"
					/>
				</Gallery>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							Sensibiliser sur un ton léger et positif
						</h3>
						<p>
							Une fois le jeu terminé, l'utilisateur peut faire don de son
							panier, c'est-à-dire offrir le montant total des oranges
							récoltées. Il peut également personnaliser le nombre d'oranges
							lui permettant de choisir la somme exacte qu'il souhaite offrir.
						</p>
						<p>
							Pour encourager la donation, nous avons démontré concrètement
							quels étaient les bénéfices que le montant allait générer sur
							le terrain. Il ne lui reste plus qu'à passer à l'action.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_3.childImageSharp}
							alt="Symbole du sourire sur fond turquoise"
						/>
						<Video
							src="0ed7766dc11653c4bbb61326384307fe"
							alt="Remerciements pour ta contribution à l'action oranges 2021"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
